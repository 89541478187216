import React, { useState } from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import { ReactComponent as CloseIcon } from '../../images/close.svg'
import { ReactComponent as ArrowRightIcon } from '../../images/arrow-right.svg'
import ctaButton from '../buttons/ctaButton'

const Wrapper = styled.div(
  ({ theme: t }) => `
  display: flex;
  width: 100%;
  height: 322px;
  background-color: ${t.colors.primaryColor};

  @media (max-width: ${t.breakpoints.lg.max}) {
    height: auto;
  }
`
)

const Content = styled.div(
  ({ theme: t }) => `
  display: flex;
  max-width: ${t.width.max};
  padding: 30px 42px;
  margin: 0 auto;
  gap: 60px;

  @media (max-width: ${t.breakpoints.md.max}) {
    padding: 30px 22.5px 70px 22.5px;
    flex-direction: column;
    gap: 20px;
  }
`
)

const Step = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  width: 345px;
  height: auto;
  color: ${t.colors.white};
  padding: 2px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media (max-width: ${t.breakpoints.lg.max}) {
    flex-direction: row;
    width: auto;
    gap: 15px;
  }
`
)

const StepIcon = styled.div(
  ({ theme: t }) => `
  min-width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: ${t.colors.red};
  text-align: center;

  span {
    font-family: Saol Display;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
  }
`
)

const TextContent = styled.div(
  ({ theme: t }) => `
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-size: 16px;
    line-height: 20px; 
    margin: 0;
  }
`
)

const Title = styled(Heading)(
  ({ theme: t }) => `
  color: ${t.colors.white};
  font-size: 28px;
  line-height: 33px;
  margin: 0;
`
)

const CalendarButton = styled.button(
  ({ theme: t }) => `
  margin-top: 10px;
  display: flex;
  width: fit-content;
  height: 45px;
  justify-content: center;
  align-items: center;
  padding: 0 15px 0 20px;
  background-color: transparent;
  color: ${t.colors.white} !important;
  border-radius: 80px;
  border: 2px solid ${t.colors.red};
  color: ${t.colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  gap: 15px;

  svg {
    min-width: 25px;
    path {
      fill: ${t.colors.white};
    }
  }

  &:hover {
    background-color: ${t.colors.red};
    border: 2px solid ${t.colors.red};

    svg {
      path {
        fill: ${t.colors.white};
      }
    }
  }
`
)

const OverrideButton = styled(ctaButton)`
  margin-top: 10px;
  display: flex;
  width: fit-content;
  height: 45px;
  justify-content: center;
  align-items: center;
  padding: 0 15px 0 20px;
  background-color: transparent;
  color: ${props => props.theme.colors.white} !important;
  border-radius: 80px;
  border: 2px solid ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  gap: 15px;

  svg {
    min-width: 25px;
    path {
      fill: ${props => props.theme.colors.white};
    }
  }

  &:hover {
    background-color: ${props => props.theme.colors.red};
    border: 2px solid ${props => props.theme.colors.red};

    svg {
      path {
        fill: ${props => props.theme.colors.white};
      }
    }
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 4, 80, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const CloseButton = styled.button(
  ({ theme: t }) => `
  position: absolute;
  top: 22px;
  right: 22px;
  background-color: transparent;
  color: ${t.colors.primaryColor};
  border: none;
  z-index: 1001;
`
)

const CalendarContainerDesktop = styled.div(
  ({ theme: t }) => `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 660px;
  height: 610px;
  border-radius: 30px;
  background-color: ${t.colors.white};
  color: ${t.colors.primaryColor};
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;

  @media (max-width: 660px) {
    width: 100%;
    margin: 20px;
  }
`
)

const defaultSteps = {
  titles: [
    'Varaa vartti vaurastumiselle',
    'Otamme sinuun *yhteyttä*',
    'Räätälöity sijoitusehdotus',
  ],
  texts: [
    'Varaa joko 15 minuutin tutustumisaika tai tunnin sijoitusneuvonta. Keskustelu kanssamme on aina maksuton eikä velvoita sinua mihinkään.',
    'Asiantuntijamme palvelee sinua henkilökohtaisesti sinulle parhaiten sopivalla tavalla.',
    'Asiantuntijamme käy kanssasi tilannettasi ja tavoitteitasi läpi ja räätälöi sinulle sopivan sijoitusehdotuksen.',
  ],
}

const StepsBar = ({ steps }) => {
  const [showCalendar, setShowCalendar] = useState(false)

  steps = steps || defaultSteps

  return (
    <>
      <Wrapper>
        <Content>
          {steps.titles.map((title, index) => (
            <Step key={index}>
              <StepIcon>
                <span>{index + 1}</span>
              </StepIcon>
              <TextContent>
                <Title>{title}</Title>
                <p>{steps.texts[index]}</p>
                {index === 0 && !steps.overrideLink && (
                  <CalendarButton onClick={() => setShowCalendar(true)}>
                    Varaa aika <ArrowRightIcon />
                  </CalendarButton>
                )}
                {index === 0 &&
                  steps.overrideLink &&
                  steps.overrideLinkText && (
                    <OverrideButton to={steps.overrideLink}>
                      {steps.overrideLinkText} <ArrowRightIcon />
                    </OverrideButton>
                  )}
              </TextContent>
            </Step>
          ))}
        </Content>
      </Wrapper>
      {showCalendar && (
        <Modal>
          <CalendarContainerDesktop>
            <CloseButton onClick={() => setShowCalendar(false)}>
              <CloseIcon />
            </CloseButton>
            <iframe
              src="https://oma.alexandria.fi/ajanvaraus"
              name="calendar"
              frameborder="0"
              height="800px"
              width="100%"
            ></iframe>
          </CalendarContainerDesktop>
        </Modal>
      )}
    </>
  )
}

export default StepsBar
