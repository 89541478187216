import React from 'react'
import styled from 'styled-components'
import CtaButton from '../buttons/ctaButton'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Heading } from '../typography/headings'
import { ReactComponent as ArrowRightIcon } from '../../images/arrow-right.svg'

const Wrapper = styled.div(
  ({ theme: t, displayImageOnRight, sijoittaminen, fullImage }) => `
  display: flex;

  max-width: ${t.width.max};
  gap: 60px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: ${displayImageOnRight ? 'row-reverse' : 'row'};

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    padding: ${
      sijoittaminen ? '30px 0' : fullImage ? '30px 0 26px 0' : '20px 0 26px 0'
    };
    flex-direction: column;
    gap: 20px;
  }

  @media screen and (min-width: ${t.breakpoints.md.min}) {
    height: 38rem;

    &:not(:last-child) {
      padding: ${
        sijoittaminen
          ? '50px 32px 0 32px'
          : fullImage
          ? '30px 32px 0 32px'
          : '30px 32px 15px 32px'
      };
    }
  
    &:last-child {
      padding: ${
        sijoittaminen
          ? '50px 32px 0 32px'
          : fullImage
          ? '30px 32px 0 32px'
          : '15px 32px 50px 32px'
      };
    }
  }
`
)

const Content = styled.div(
  ({ theme: t, sijoittaminen }) => `
  color: ${t.colors.blue};
  gap: 45px;
  word-break: break-word;
  text-align: left;
  justify-content: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > * {
    margin: 0;
  }

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    padding: ${sijoittaminen ? '20px 20px 6px 20px' : '20px'};
    gap: 30px;
  }
`
)

const Title = styled(Heading)(
  ({ theme: t }) => `
  font-size: 45px;
  line-height: 50px;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    font-size: 28px;
    line-height: 33px;
  }
`
)

const ImageWrap = styled.div(
  ({ theme: t, fullImage }) => `
  width: 50%;
  height: 100%;
  aspect-ratio: ${fullImage ? '1/1' : '640/670'};
  overflow: hidden;
  align-self: center;

  @media screen and (max-width: ${t.breakpoints.md.max}) {
    width: 100%;
    padding: 0 2rem;
    aspect-ratio: ${fullImage ? '1/1' : '390/283'};
  }

  @media screen and (max-width: ${t.breakpoints.sm.min}) {
    padding: 0;
  }
`
)

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Description = styled.p`
  font-size: 20px;
  line-height: 28px;
`

const LinkButton = styled(CtaButton)(
  ({ theme: t, fullImage }) => `
  width: fit-content;
  display: inline-flex;
  height: 45px;
  padding: 13px;
  gap: 4px;
  align-items: center;
  border-radius: 80px;
  border: 2px solid ${t.colors.primaryColor};

  span {
    width: 100%;
    text-wrap: nowrap;
  }

  svg {
    min-width: 25px;
    max-width: 25px;
    margin-left: auto;
  }

  &:hover {
    color: ${t.colors.white} !important;
      path {
        fill: ${t.colors.white};
      }
    }
  }
`
)

const FeaturedArticleHighlight = ({
  featuredArticle,
  sijoittaminen,
  fullImage,
}) => {
  return (
    <Wrapper
      displayImageOnRight={featuredArticle.displayImageOnRight}
      sijoittaminen={sijoittaminen}
      fullImage={fullImage}
    >
      <ImageWrap fullImage={fullImage}>
        <StyledGatsbyImage
          image={featuredArticle.image.gatsbyImageData}
          alt={featuredArticle.image.title || ''}
        />
      </ImageWrap>
      <Content sijoittaminen={sijoittaminen}>
        <Title as="h2" headingSize="3">
          {featuredArticle.title}
        </Title>
        <Description>
          {featuredArticle.text.childMarkdownRemark?.html ? (
            <div
              dangerouslySetInnerHTML={{
                __html: featuredArticle.text.childMarkdownRemark.html,
              }}
            />
          ) : (
            featuredArticle.text.text
          )}
        </Description>
        <LinkButton
          to={featuredArticle.link}
          large="true"
          fullImage={fullImage}
        >
          <span>{featuredArticle.linkText || 'Lue lisää'}</span>{' '}
          <ArrowRightIcon />
        </LinkButton>
      </Content>
    </Wrapper>
  )
}

export default FeaturedArticleHighlight
