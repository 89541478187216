import React from 'react'
import styled from 'styled-components'

const FeaturedWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing.xxxlarge} 0;
`

const FeaturedContent = styled.div`
  width: ${props => props.theme.width.contentNew};
  text-align: center;
`

const FeaturedPagesHeader = ({ content }) => {
  return (
    <FeaturedWrapper>
      <FeaturedContent dangerouslySetInnerHTML={{ __html: content }} />
    </FeaturedWrapper>
  )
}

export { FeaturedPagesHeader }
