import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { pinkTheme } from '../styles/theme'
import { CampaignHero } from '../components/campaignPage/campaignHero'
import { CampaignCTASegment } from '../components/campaignPage/campaignCTASegment'
import { MainMediaSegment } from '../components/campaignPage/mainMediaSegment'
import { FeatureSegment } from '../components/campaignPage/featureSegment'
import { CampaignNewsletter } from '../components/campaignPage/campaignNewsletter'
import { SecondaryMediaSegment } from '../components/campaignPage/secondaryMediaSegment'
import { MicrosoftClarityScript } from '../lib/ms-clarity'
import { FeaturedPagesHeader } from '../components/campaignPage/featuredPagesHeader'
import FeaturedArticleHighlight from '../components/featuredArticleHighlight/featuredArticleHighlight'
import ProductInfoSection from '../components/productInfoSection/productInfoSection'
import StepsBar from '../components/stepsBar/stepsBar'
import styled from 'styled-components'

const FeaturedArticleHighlightWrapper = styled.div`
  margin: 0 0 ${props => props.theme.spacing.xlarge} 0;

  @media (max-width: ${props => props.theme.breakpoints.sm.max}) {
    margin: 0 0 ${props => props.theme.spacing.small} 0;
  }
`

const CampaignPage = ({ data }) => {
  const pageData = data.contentfulCampaignPage
  return (
    <Layout title={pageData.title} navbarTheme={pinkTheme}>
      <CampaignHero
        media={{
          url: pageData.heroBackground.url,
          type: pageData.heroBackground.mimeType,
        }}
        heading={pageData.heroTitle}
        body={pageData.heroBody}
        featureImage={pageData.heroFeatureImage.url}
        ctaText={pageData.heroCtaText}
        ctaTarget={pageData.heroCtaTarget}
        badge={pageData.heroBadge.internal.content || {}}
      />
      <CampaignCTASegment
        heading={pageData.mainHeading}
        body={pageData.mainBody}
        ctaText={pageData.mainCtaText}
        ctaTarget={pageData.mainCtaTarget}
      />
      <MainMediaSegment
        mediaUrl={pageData.mainMedia.url}
        header={pageData.mainMediaHeader}
        body={pageData.mainMediaBody}
        poster={pageData.mainMediaPlaceholder.url}
      />
      {pageData.steps && <StepsBar steps={pageData.steps} />}
      {pageData.showFeatures && (
        <FeatureSegment
          header={pageData.featureHeader}
          body={pageData.featureBody}
          config={pageData.featureConfig.internal.content}
        />
      )}
      {pageData.secondaryMedia &&
        pageData.secondaryMediaMobile &&
        pageData.secondaryMediaHeader &&
        pageData.secondaryMediaBody && (
          <SecondaryMediaSegment
            mediaUrl={pageData.secondaryMedia.url}
            mobileUrl={pageData.secondaryMediaMobile.url}
            header={pageData.secondaryMediaHeader}
            body={pageData.secondaryMediaBody}
          />
        )}
      {pageData.tertiaryMedia &&
        pageData.tertiaryMediaMobile &&
        pageData.tertiaryMediaHeader &&
        pageData.tertiaryMediaBody && (
          <SecondaryMediaSegment
            mediaUrl={pageData.tertiaryMedia.url}
            mobileUrl={pageData.tertiaryMediaMobile.url}
            header={pageData.tertiaryMediaHeader}
            body={pageData.tertiaryMediaBody}
            reverse
          />
        )}
      {pageData.featuredPagesHeader && (
        <FeaturedPagesHeader
          content={pageData.featuredPagesHeader.childMarkdownRemark.html}
        />
      )}
      {pageData.featuredPages?.map((page, index) => (
        <FeaturedArticleHighlightWrapper key={'featured-' + index}>
          <FeaturedArticleHighlight featuredArticle={page} />
        </FeaturedArticleHighlightWrapper>
      ))}
      {pageData.showNewsletterForm && (
        <CampaignNewsletter
          header={pageData.newsletterHeader}
          formBody={pageData.newsletterFormBody}
          sideBody={pageData.newsletterSideBody}
          footer={pageData.newsletterFooter}
        />
      )}
      {pageData.productInfoSections && (
        <ProductInfoSection
          productInfoSections={pageData.productInfoSections}
        />
      )}
    </Layout>
  )
}

export default CampaignPage

export function Head() {
  return <MicrosoftClarityScript />
}

export const query = graphql`
  query ($slug: String!) {
    contentfulCampaignPage(slug: { eq: $slug }) {
      slug
      title
      heroTitle {
        childMarkdownRemark {
          html
        }
      }
      heroBody {
        childMarkdownRemark {
          html
        }
      }
      heroCtaText
      heroCtaTarget
      heroBackground {
        mimeType
        url
      }
      heroFeatureImage {
        url
      }
      heroBadge {
        internal {
          content
        }
      }
      mainHeading
      mainBody {
        childMarkdownRemark {
          html
        }
      }
      mainCtaText
      mainCtaTarget
      mainMedia {
        mimeType
        url
      }
      mainMediaPlaceholder {
        url
      }
      mainMediaHeader
      mainMediaBody {
        childMarkdownRemark {
          html
        }
      }
      steps {
        __typename
        ... on ContentfulStepsBar {
          __typename
          name
          titles
          texts
          buttonText
          overrideLink
          overrideLinkText
        }
      }
      showFeatures
      featureHeader
      featureBody {
        childMarkdownRemark {
          html
        }
      }
      featureConfig {
        internal {
          content
        }
      }
      secondaryMedia {
        mimeType
        url
      }
      secondaryMediaMobile {
        mimeType
        url
      }
      secondaryMediaHeader {
        childMarkdownRemark {
          html
        }
      }
      secondaryMediaBody {
        childMarkdownRemark {
          html
        }
      }
      tertiaryMedia {
        mimeType
        url
      }
      tertiaryMediaMobile {
        mimeType
        url
      }
      tertiaryMediaHeader {
        childMarkdownRemark {
          html
        }
      }
      tertiaryMediaBody {
        childMarkdownRemark {
          html
        }
      }
      featuredPagesHeader {
        childMarkdownRemark {
          html
        }
      }
      featuredPages {
        __typename
        ... on ContentfulFeaturedArticleHighlight {
          __typename
          name
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          title
          text {
            childMarkdownRemark {
              html
            }
          }
          linkText
          link
          displayImageOnRight
        }
      }
      productInfoSections {
        __typename
        ... on Node {
          ... on ContentfulProductInfoModule {
            name
            linksTitle
            linkTitle
            textField {
              childMarkdownRemark {
                html
              }
            }
            additionalTextField {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      showNewsletterForm
      newsletterHeader {
        childMarkdownRemark {
          html
        }
      }
      newsletterFormBody {
        childMarkdownRemark {
          html
        }
      }
      newsletterSideBody {
        childMarkdownRemark {
          html
        }
      }
      newsletterFooter {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
